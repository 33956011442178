@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700|Roboto+Mono");

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

dialog {
  border: none;
}
